import React, { Component } from "react";
import {Helmet} from 'react-helmet'

class PageHelmet extends Component{
    render(){
        return(
            <React.Fragment>
                <Helmet>
                    <title>{this.props.pageTitle} | Software Engineer </title>
                    <meta name="description" content="A passionate competitive programmer, software engineer and data scientist based in Bangladesh, who like to immerse himself in continuous learning, always eager to learn new technologies with his fast learning ability." />
                    <meta name="keyword" content="sazid nur ratul, software engineer, sazidnur, sazid nur, best software engineer in bangladesh, top software engineer in bangladesh, competitive programmer, data scientist, bangladeshi data scientist"></meta>
                    
                    <meta property="og:title" content="Sazid Nur Ratul | Software Engineer"/>
                    <meta property="og:description" content="A passionate competitive programmer and software engineer, who like to immerse himself in continuous learning, always eager to learn new technologies with his fast learning ability." />
                    <meta property="og:image" content="https://sazidnur.com/assets/images/about/about-9.jpg"/>
                    <meta property="og:url" content="https://sazidnur.com" />
                    <meta property="og:type" content="website" />

                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:title" content="Sazid Nur Ratul | Software Engineer" />
                    <meta name="twitter:description" content="A passionate competitive programmer and software engineer, who like to immerse himself in continuous learning, always eager to learn new technologies with his fast learning ability."/>
                    <meta name="twitter:image" content="https://sazidnur.com/assets/images/about/about-9.jpg"/>
                </Helmet>
            </React.Fragment>
        )
    }
}


export default PageHelmet;
