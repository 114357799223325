const BlogContent = [
    {
        images: '01',
        title: 'Predictive Analytics on Contestants Performance in Competitive Programming',
        category: 'Machine Learning'
    },
    
    // {
    //     images: '02',
    //     title: 'A big ticket gone to be an interesting ',
    //     category: 'Management'
    // },

    // {
    //     images: '03',
    //     title: 'The Home of the Future Could Bebes',
    //     category: 'Design'
    // },
    // {
    //     images: '04',
    //     title: ' Getting tickets to the big show',
    //     category: 'Development'
    // },
    // {
    //     images: '01',
    //     title: 'A big ticket gone to be an interesting ',
    //     category: 'Management'
    // },
    // {
    //     images: '02',
    //     title: 'The Home of the Future Could Bebes',
    //     category: 'Design'
    // },
]

export default BlogContent;