import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

class TabsTwo extends Component{
    render(){
        let 
        tab1 = "Skills",
        tab2 = "Awards & Certificates",
        tab3 = "Voluntary",
        tab4 = "Education",
        tab5 = "Experience";

        const { tabStyle, data, item } = this.props
        const isMobile = window.innerWidth <= 768;

        const cpskillList = data?.cp_titles?.slice(0, item) || [];
        const skillList = data?.skill_titles?.slice(0, item) || [];

        const experienceList = (data?.experience || [])
            .filter(exp => exp.voluntary !== 1) // Filter out voluntary jobs
            .sort((a, b) => {
                if (a.end_month === "Current") return -1; // Current job comes first
                if (b.end_month === "Current") return 1;
                return (b.start_year - a.start_year) || // Sort by year, descending
                    (new Date(b.start_month) - new Date(a.start_month)); // Sort by month if year is the same
            });
        
            const voluantaryList = (data?.experience || [])
            .filter(exp => exp.voluntary !== 0) // Filter out voluntary jobs
            .sort((a, b) => {
                if (a.end_month === "Current") return -1; // Current job comes first
                if (b.end_month === "Current") return 1;
                return (b.start_year - a.start_year) || // Sort by year, descending
                    (new Date(b.start_month) - new Date(a.start_month)); // Sort by month if year is the same
            });
        
        const educationList = (data?.education || [])
            .sort((a, b) => {
                if (a.end_month === "Current") return -1; // Last education comes first
                if (b.end_month === "Current") return 1;
                return (b.start_year - a.start_year) || // Sort by year, descending
                    (new Date(b.start_month) - new Date(a.start_month)); // Sort by month if year is the same
            });
        
        const awardList = data?.awards || [];
        
        return(
            <div>
                {/* Start Tabs Area */}
                <div className="tabs-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <Tabs>
                                    <TabList  className={`${tabStyle}`}>
                                        <Tab>{tab1}</Tab>
                                        <Tab>{tab5}</Tab>
                                        <Tab>{tab4}</Tab>
                                        <Tab>{tab2}</Tab>
                                        <Tab>{tab3}</Tab>
                                    </TabList>

                                    <TabPanel>
                                        <div className="single-tab-content">
                                            
                                            <ul>
                                                {skillList.map((skillSection, index) => (
                                                    <li key={index}>
                                                        <a href="#">{skillSection.title}</a>
                                                        {skillSection.skills.map((skill, idx) => (
                                                            idx === skillSection.skills.length - 1 ? skill.name : `${skill.name} | `
                                                        ))}
                                                    </li>
                                                ))}
                                            </ul>
                                            <ul>
                                                {cpskillList.map((cpskillSection, index) => (
                                                    <li key={index}>
                                                    <a href="">{cpskillSection.title}</a>
                                                    <p>{cpskillSection.description}</p>
                                                    {cpskillSection.cpskills.map((cpskill, idx) => 
                                                        isMobile ? (
                                                            <div key={idx}>
                                                                <a class="sameline" href={cpskill.link} target="_blank" rel="noopener noreferrer">{cpskill.name}</a><p>{" - "}{cpskill.desc}</p>
                                                            </div>
                                                        ) : (
                                                            <div style={{
                                                                    alignItems: 'center',
                                                                    width: cpskill.desc.length <= 25 ? '50%' : '100%',
                                                                    float: cpskill.desc.length <= 25 ? 'left' : 'none',
                                                                }}>
                                                                <a class="sameline" href={cpskill.link} target="_blank" rel="noopener noreferrer">{cpskill.name}</a><p>{" - "}{cpskill.desc}</p>
                                                            </div>
                                                        )
                                                    )}
                                                </li>
                                                
                                                ))}
                                            </ul>
                                        </div>
                                    </TabPanel>

                                    <TabPanel>
                                       <div className="single-tab-content">
                                            <ul>
                                                {experienceList.map((experience, index) => (
                                                    <li key={index}>
                                                        <a href="#">
                                                            {experience.title}
                                                            <span> - {experience.organization}{experience.location ? ', ' + experience.location : ''}</span>
                                                        </a> 
                                                        {experience.start_month} {experience.start_year} - {experience.end_month} {experience.end_year ? experience.end_year : ''}
                                                    </li>
                                                ))}
                                            </ul>
                                       </div>
                                    </TabPanel>

                                    <TabPanel>
                                       <div className="single-tab-content">
                                            <ul>
                                                {educationList.map((education, index) => (
                                                    <li key={index}>
                                                        <a href={education.link}>
                                                            {education.degree}
                                                            <span> - {education.institution}{education.location ? ', ' + education.location : ''}</span>
                                                        </a> 
                                                        {education.start_month} {education.start_year} - {education.end_month} {education.end_year ? education.end_year : ''}
                                                    </li>
                                                ))}
                                            </ul>
                                       </div>
                                    </TabPanel>
                                    
                                    <TabPanel>
                                       <div className="single-tab-content">
                                            <ul>
                                                {awardList.map((award, index) => (
                                                    <li key={index}>
                                                        <a href={award.link || "#"} target="_blank" rel="noopener noreferrer">
                                                            {award.name} <span>- {award.title}</span>
                                                        </a> {award.description}
                                                    </li>
                                                ))}
                                            </ul>
                                       </div>
                                    </TabPanel>

                                    <TabPanel>
                                       <div className="single-tab-content">
                                            <ul>
                                                {voluantaryList.map((experience, index) => (
                                                    <li key={index}>
                                                        <a href="#">
                                                            {experience.title}
                                                            <span> - {experience.organization}{experience.location ? ', ' + experience.location : ''}</span>
                                                        </a> 
                                                        {experience.start_month} {experience.start_year} - {experience.end_month} {experience.end_year ? experience.end_year : ''}
                                                    </li>
                                                ))}
                                            </ul>
                                       </div>
                                    </TabPanel>
                                </Tabs>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Tabs Area */}
            </div>
        )
    }
}



export default TabsTwo;