import React ,{ Component }from "react";
import * as FiIcons from "react-icons/fi";


class ServiceThree extends Component{
    render(){
        const { column, data } = this.props;
        const ServiceContent = Array.isArray(data) ? data.slice(0, this.props.item) : [];
        return(
            <React.Fragment>
                <div className="row">
                    {ServiceContent.map( (val , i) => {
                        const IconComponent = FiIcons[val.icon];
                        return(
                            <div className={`${column}`} key={i}>
                                <a href="#contact">
                                    <div className="service service__style--2">
                                        <div className="icon">
                                            {IconComponent && <IconComponent />}
                                        </div>
                                        <div className="content">
                                            <h3 className="title">{val.title}</h3>
                                            <p>{val.description}</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        )
                    })}
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceThree;
