import React, { Component } from "react";
import {Link} from "react-router-dom";
import { API_URL } from "../../App";

const PortfolioListContent = [
    {
        image: 'image-1',
        category: 'Node.js, MongoDB',
        title: 'Maternal Health Care',
        link: 'http://maternalhealth-care.herokuapp.com/'
    },
    {
        image: 'image-2',
        category: 'C#',
        title: 'QPack',
        link: 'https://github.com/sazidnur/QPack'
    },
    {
        image: 'image-3',
        category: 'Python, PyQt',
        title: 'IOM Analyzer',
        link: 'https://github.com/sazidnur/un_iom'
    },
    // {
    //     image: 'image-4',
    //     category: 'Python, Automation',
    //     title: 'Vjudge to Oj',
    //     link: '/hh'
    // }
]

class PortfolioList extends Component{
    render(){
        const {column , styevariation, portfolioData } = this.props;
        const list = portfolioData.slice(0 , this.props.item);
        return(
            <React.Fragment> 
                {list.map((value , index) => (
                    <div className={`${column}`} key={index}>
                        <div className={`portfolio ${styevariation}`}>
                            <div className="thumbnail-inner">
                            {value.image && value.image.startsWith('/media/images') ? (
                                <>
                                    <div className="thumbnail" style={{ backgroundImage: `url(${API_URL+value.image})` }}></div>
                                    <div className="bg-blr-image" style={{ backgroundImage: `url(${API_URL+value.image})` }}></div>
                                </>
                            ) : (
                                <>
                                    <div className={`thumbnail ${value.image}`}></div>
                                    <div className={`bg-blr-image ${value.image}`}></div>
                                </>
                            )}
                            </div>
                            <div className="content">
                                <div className="inner">
                                    <p>{value.technology}</p>
                                    <h4><a href={value.link} target="_blank" rel="noopener noreferrer" >{value.title}</a></h4>
                                    <div className="portfolio-button">
                                        <a className="rn-btn" href={value.link} target="_blank" rel="noopener noreferrer" >View Details</a>
                                    </div>
                                </div>
                            </div>
                            <Link className="link-overlay" to={{ pathname: value.link }} target="_blank" rel="noopener noreferrer" ></Link>
                        </div>
                    </div>
                ))}
               
            </React.Fragment>
        )
    }
}

// Setting defaultProps for PortfolioList
PortfolioList.defaultProps = {
    portfolioData: PortfolioListContent
};

export default PortfolioList;