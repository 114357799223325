import React, { useState, useEffect } from 'react';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Helmet from "../component/common/Helmet";
import TextLoop from "react-text-loop";
import HeaderThree from "../component/header/HeaderThree";
import FooterTwo from "../component/footer/FooterTwo";
import TabTwo from "../elements/tab/TabTwo";
import ContactThree from "../elements/contact/ContactThree";
import PortfolioList from "../elements/portfolio/PortfolioList";
import ServiceList from "../elements/service/ServiceList";
import BlogContent from "../elements/blog/BlogContent";
import { API_URL } from '../App';


const SlideList = [
    {
        textPosition: 'text-left',
        category: 'Welcome to my World',
        description: '',
        buttonText: 'Linkedin Profile',
        buttonLink: '#'
    }
]
const PortfolioLanding = ({data}) => {
    let title = 'About Me', description = data?.about?.about_me;
    const ResearchList = data?.researches?.slice(0 , 3) || [];
    const isMobileDevice = () => {
      return window.innerWidth <= 768;
    };

    const defaultTitles = ['Software Engineer', 'Problem Solver', 'Backend Developer'];

    const titles = [
        (isMobileDevice() && (data?.about?.title1?.length > 17)) ? defaultTitles[0] : data?.about?.title1 || defaultTitles[0],
        (isMobileDevice() && (data?.about?.title2?.length > 17)) ? defaultTitles[1] : data?.about?.title2 || defaultTitles[1],
        (isMobileDevice() && (data?.about?.title3?.length > 17)) ? defaultTitles[2] : data?.about?.title3 || defaultTitles[2],
    ];
    
    return (
        <div className="active-dark">
            <Helmet pageTitle="Sazid Nur Ratul" />

            <HeaderThree homeLink="/" logo="symbol-dark" color="color-black" data={ data?.about } />
            {/* Start Slider Area   */}
            <div id="home" className="fix">
                <div className="slider-wrapper">
                    {/* Start Single Slide */}
                    {SlideList.map((value , index) => (
                        <div className="slide personal-portfolio-slider slider-paralax slider-style-3 d-flex align-items-center justify-content-center bg_image bg_image--25" key={index}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className={`inner ${value.textPosition}`}>
                                            {value.category ? <span>{value.category}</span> : ''}
                                            <h1 className="title">Hi, I’m Sazid Nur<br/>
                                            <TextLoop>
                                                {titles.map((title, index) => (
                                                    <span key={index} class = "text-loop-span">{title}</span>
                                                ))}
                                            </TextLoop>
                                            {" "}
                                            </h1>
                                            <h2>based in {data?.about?.based_country}.</h2>
                                            {value.description ? <p className="description">{value.description}</p> : ''}
                                            {value.buttonText ? <div className="slide-btn"><a className="rn-button-style--2 btn-primary-color" target="blank" rel="noopener noreferrer"  href={`${data?.about?.linkedin_link}`}>{value.buttonText}</a></div> : ''}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                    {/* End Single Slide */}
                </div>
            </div>
            {/* End Slider Area   */} 

            {/* Start About Area */}
            <div id="about" className="fix">
                <div className="about-area ptb--120  bg_color--1">
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">
                                <div className="col-lg-5">
                                    <div className="thumbnail">
                                        <img className="w-100" src={`${API_URL}${data?.about?.about_image}`} alt="Sazid Nur Ratul"/>
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                            <h2 className="title">{title}</h2>
                                            <p className="description">{description}</p>
                                        </div>
                                        <div className="row mt--30">
                                            <TabTwo tabStyle="tab-style--1" data={ data }/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            {/* End About Area */}

            {/* Start Service Area  */}
            <div id="service" className="fix">
                <div className="service-area creative-service-wrapper ptb--120 bg_color--5" >
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title text-center service-style--3 mb--30 mb_sm--0">
                                    <h2 className="title">My Awesome Service</h2>
                                    <p>"Quality, Service, Cleanliness, and Value." <br /> - Cavett Robert </p>
                                </div>
                            </div>
                        </div>
                        <div className="row creative-service">
                            <div className="col-lg-12">
                                <ServiceList item="6" column="col-lg-4 col-md-6 col-sm-6 col-12 text-left" data={ data?.service } />
                            </div>
                        </div>
                    </div>
                </div>  
            </div>
            {/* End Service Area  */} 

            {/* Start Portfolio Area */}
            <div id="portfolio" className="fix">
                <div className="portfolio-area ptb--120 bg_color--1">
                    <div className="portfolio-sacousel-inner">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title text-center service-style--3 mb--30 mb_sm--0">
                                        <h2 className="title">Latest Project</h2>
                                        <p>"Sooner or later, those who win are those who thinks they can." <br /> - Paul Tournier </p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <PortfolioList styevariation="text-center mt--40" column="col-lg-4 col-md-6 col-sm-6 col-12" item="6" portfolioData={data?.projects}/>
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="view-more-btn mt--60 mt_sm--30 text-center">
                                        <a className="rn-button-style--2 btn-solid" href={`${data?.about?.github_link}?tab=repositories`} target='_blank' rel="noopener noreferrer"><span>View More</span></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Portfolio Area */}

            {/* Start Research Area */}
            <div id="research" className="fix">
                <div className="rn-blog-area ptb--120 bg_color--5 mb-dec--30">
                    <div className="container">
                        <div className="row align-items-end">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                <div className="section-title text-center">
                                    <h2>Research</h2>
                                    <p>“No research without action, no action without research” <br />- Kurt Lewin</p>
                                </div>
                            </div>
                        </div>
                        <div className="row mt--60 mt_sm--40">
                            {ResearchList.map((value , i ) => (
                                <div className="col-lg-4 col-md-6 col-12" key={i}>
                                    <div className="blog blog-style--1">
                                        <div className="thumbnail">
                                            <a href="#">
                                                <img className="w-100" src={`${API_URL}${value.image}`} alt="Blog Images"/>
                                            </a>
                                        </div>
                                        <div className="content">
                                            <p className="blogtype">{value.technology}</p>
                                            <h4 className="title"><a href={value.link} target='_blank' rel="noopener noreferrer" >{value.title}</a></h4>
                                            <div className="blog-btn">
                                                <a className="rn-btn text-white" href={value.link} target='_blank' rel="noopener noreferrer" >Read More</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>    
                    </div>    
                </div>
            </div>
            {/* End Research Area */}

            {/* Start Contact Area */}
            <div id="contact" className="fix">
                <div className="rn-contact-area ptb--120 bg_color--1">
                    <ContactThree contactImages={`${API_URL}${data?.about?.footer_image}`} contactTitle="Ping Me." />
                </div>
            </div>
            {/* End COntact Area */}

            <FooterTwo data={data?.about} />
            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop showUnder={160}>
                    <FiChevronUp />
                </ScrollToTop>
            </div>
            {/* End Back To Top */}
            
        </div>
    )
}

export default PortfolioLanding;
