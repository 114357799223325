import React, { useState, useEffect } from 'react';
import PortfolioLanding from './dark/PortfolioLanding';
import { WifiLoader  } from "react-awesome-loaders";

const API_URL = "https://api.sazidnur.com";

function App() {
    const [data, setData] = useState(null);

    useEffect(() => {
        fetch(API_URL + '/info/')
        .then(response => response.json())
        .then(data => setData(data))
        .catch(error => console.error('Error fetching the data', error));
    }, []);
    console.log(data);

    const loaderStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        background: '#101010'
    };

    return (
        <div className="App">
            {data === null ? (
                <div style={loaderStyle}>
                    <WifiLoader
                        background={"#101010"}
                        desktopSize={"128px"}
                        mobileSize={"64px"}
                        text={"Loading..."}
                        backColor="#E8F2FC"
                        frontColor="#f9004d"
                    />
                </div>
            ) : (
                <PortfolioLanding data={data} />
            )}
        </div>
    );
}

export { API_URL };
export default App;
