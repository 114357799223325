import React, { Component } from "react";

class ContactThree extends Component{
    constructor(props){
        super(props);
        this.state = {
            rnName: '',
            rnEmail: '',
            rnSubject: '',
            rnMessage: '',
        };
        this.sendEmail = this.sendEmail.bind(this);
    }

    sendEmail(e){
        e.preventDefault();

        // Create a new FormData object from the form in the event object
        let formData = new FormData(e.target);

        // Make a POST request to your Django API
        fetch('https://api.sazidnur.com/contact/submit/', {
            method: 'POST',
            body: formData
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(data => {
            console.log(data)
            this.setState({
                rnName: '',
                rnEmail: '',
                rnSubject: '',
                rnMessage: '',
                serverResponse: data.message,
            });

            setTimeout(() => {
                this.setState({
                    serverResponse: ''
                });
            }, 5000);
        })
        .catch(error => {
            console.log('There was an error!', error);
            this.setState({
                serverResponse: 'There was an error!',
            });
            
            setTimeout(() => {
                this.setState({
                    serverResponse: ''
                });
            }, 5000);
        });

        e.target.reset();

    };
    render(){
        let notificationStyle = {
            fontSize: '15px',
            color: '#d4d4d4',
        };
        return(
            <div className="contact-form--1">
                <div className="container">
                    <div className="row row--35 align-items-start">
                        <div className="col-lg-6 order-2 order-lg-1">
                            <div className="section-title text-left mb--50">
                                <h2 className="title">{this.props.contactTitle}</h2>
                                <p className="description">I am available for freelance work. Connect with me via email:
                                    <a href="mailto:contact@sazidnur.com"> contact@sazidnur.com</a> </p>
                            </div>
                            <div className="form-wrapper">
                                <p style={notificationStyle}>{this.state.serverResponse}</p>
                                <form onSubmit={this.sendEmail}>
                                    <label htmlFor="item01">
                                        <input
                                            type="text"
                                            name="name"
                                            id="item01"
                                            value={this.state.rnName}
                                            onChange={(e)=>{this.setState({rnName: e.target.value});}}
                                            placeholder="Your Name *"
                                        />
                                    </label>

                                    <label htmlFor="item02">
                                        <input
                                            type="text"
                                            name="email"
                                            id="item02"
                                            value={this.state.rnEmail}
                                            onChange={(e)=>{this.setState({rnEmail: e.target.value});}}
                                            placeholder="Your email *"
                                        />
                                    </label>

                                    <label htmlFor="item03">
                                        <input
                                            type="text"
                                            name="subject"
                                            id="item03"
                                            value={this.state.rnSubject}
                                            onChange={(e)=>{this.setState({rnSubject: e.target.value});}}
                                            placeholder="Write a Subject *"
                                        />
                                        <input type="text" name="issue" style={{display: "none"}} />
                                    </label>
                                    <label htmlFor="item04">
                                        <textarea
                                            type="text"
                                            id="item04"
                                            name="message"
                                            value={this.state.rnMessage}
                                            onChange={(e)=>{this.setState({rnMessage: e.target.value});}}
                                            placeholder="Your Message *"
                                        />
                                    </label>
                                    <button className="rn-button-style--2 btn-solid" type="submit" value="submit" name="submit" id="mc-embedded-subscribe">Submit</button>
                                </form>
                            </div>
                        </div>
                        <div className="col-lg-6 order-1 order-lg-2">
                            <div className="thumbnail mb_md--30 mb_sm--30">
                                <img width="75%" src={`${this.props.contactImages}`} alt="Sazid Nur Ratul"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default ContactThree;